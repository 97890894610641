<script setup lang="ts">
import { ref, computed } from 'vue'
import pDebounce from 'p-debounce'

import {
  IconTemperatureSnow,
  IconTemperatureSun,
  IconTemperature,
  IconPlus,
  IconMinus
} from '@tabler/icons-vue'

import trpc from '../lib/trpc'

import type { LinkDetail, LinkUpdated, LabelsFilter, Page } from 'server'

const props = defineProps<{ link: LinkDetail; filter: LabelsFilter; page: Page }>()
const emit = defineEmits<{ update: [LinkUpdated] }>()

const thermsLoading = ref(false)
const thermsOverride = ref<number | null>(null)
const submitAlteredTherms = pDebounce(async () => {
  thermsLoading.value = true
  try {
    const data = await trpc.alterTherms.mutate({
      linkId: props.link.link_id,
      filter: props.filter,
      page: props.page,
      value: alteredTherms.value
    })
    emit('update', data)
  } finally {
    thermsLoading.value = false
    // Run this in the `finally` block so that it's transparent when the server
    // errors out and doesn't change the therms value
    thermsOverride.value = null
  }
}, 500)

const alterTherms = async (delta: number) => {
  thermsOverride.value = (thermsOverride.value ?? props.link.therms) + delta
  await submitAlteredTherms()
}

const alteredTherms = computed(() => thermsOverride.value ?? props.link.therms)
</script>

<template>
  <div
    class="flex items-center ml-auto rounded-lg"
    :class="{
      'bg-orange-50': alteredTherms > 0,
      'bg-cyan-50': alteredTherms < 0,
      'bg-gray-100': alteredTherms === 0
    }"
  >
    <button
      size="small"
      @click="alterTherms(1)"
      class="text-xl text-orange-400 px-2 py-1"
      data-testid="therms-plus"
    >
      <IconPlus />
    </button>

    <div v-if="!thermsLoading" class="flex items-center">
      <span
        class="text-black font-semibold"
        :class="{
          'text-orange-900': alteredTherms > 0,
          'text-cyan-900': alteredTherms < 0,
          'text-grey-900': alteredTherms === 0,
          // The neutral temperature icon has the same image width but less
          // visual content so its alignment doesn't match the warm/cold
          // temp icons
          '-mr-1.5': alteredTherms === 0
        }"
        _style="text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2)"
        data-testid="therms-value"
      >
        {{ alteredTherms }}
      </span>
      <IconTemperatureSun v-if="alteredTherms > 0" class="text-orange-500" />
      <IconTemperatureSnow v-else-if="alteredTherms < 0" class="text-cyan-500" />
      <IconTemperature v-else class="text-gray-400" :class="[alteredTherms === 0 && '-mr-1.5']" />
    </div>
    <sl-spinner v-else />

    <button
      size="small"
      @click="alterTherms(-1)"
      class="text-xl text-cyan-400 px-2 py-1"
      data-testid="therms-minus"
    >
      <IconMinus />
    </button>
  </div>
</template>
