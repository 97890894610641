<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useHoneybadger } from '@honeybadger-io/vue'

import Button from 'primevue/button'

import { IconMistOff, IconReload, IconTag } from '@tabler/icons-vue'

import { sortLinks, sortLabels } from '../lib/util'
import trpc from '../lib/trpc'
import AddLink from '@/components/AddLink.vue'
import LinkListItem from '../components/LinkListItem.vue'
import ListItemSkeleton from '../components/LinkListItemSkeleton.vue'
import LabelsSkeleton from '../components/LabelsSkeleton.vue'
import Sidebar from '../components/sidebar/Sidebar.vue'
import styles from './home.module.css'

import type { UserLinks, LinkUpdated, LinkDetail, LabelsFilter, Page } from 'server'

const honeybadger = useHoneybadger()

const router = useRouter()
const route = useRoute()
const store = ref<UserLinks>({
  links: {},
  labels: {},
  prevPageToken: null,
  nextPageToken: null
})
const isLoading = ref(true)
const clearReadLoading = ref(false)

const labelsFilter = computed<LabelsFilter>(() => {
  const labelsAll = [route.query.labels_all?.toString().split(',')].flat().filter(Boolean)
  return {
    all: labelsAll.length > 0 ? labelsAll : undefined,
    domain: route.query.domain?.toString(),
    trashed: route.query.trashed === 'true'
  }
})

const pageNum = computed(
  (): Page =>
    route.query.page !== undefined ? (JSON.parse(atob(route.query.page as string)) as Page) : null
)

const decodedPage = computed(() =>
  route.query.page ? JSON.parse(atob(route.query.page as string)) : null
)

const sortedLinks = computed(() => sortLinks(store.value?.links ?? {}, labelsFilter.value.trashed))
const labelsSorted = computed(() => sortLabels(store.value.labels))

let skipNextRefresh = false
const refresh = async () => {
  if (skipNextRefresh) {
    skipNextRefresh = false
    return
  }
  isLoading.value = true
  try {
    const result = await trpc.getLinks.query({
      filter: labelsFilter.value,
      page: pageNum.value
    })
    store.value = result

    if (!store.value.prevPageToken && route.query.page) {
      console.log('Cleaning up URL')
      // Clean up the URL both for prettiness sake, to prevent issues if the
      // user bookmarks the page, and so we KNOW we're managing the top of the
      // page and not some paginated value.
      skipNextRefresh = true
      router.replace({
        query: {
          ...route.query,
          page: undefined
        }
      })
    }
  } finally {
    isLoading.value = false
  }
}

const adjacentPageUrl = (direction: 'previous' | 'next') => {
  const baselineLink =
    direction === 'previous'
      ? sortedLinks.value.at(0)
      : sortedLinks.value.at(sortedLinks.value.length - 1)
  // Empty data set
  if (!baselineLink) return { query: { ...route.query, page: undefined } }

  const page: Page = {
    direction,
    sortKey: baselineLink.sort_key
  }

  const pageEncoded = btoa(JSON.stringify(page))

  return {
    query: { ...route.query, page: pageEncoded }
  }
}

const clearRead = async () => {
  clearReadLoading.value = true
  try {
    const result = await trpc.clearRead.mutate({
      filter: labelsFilter.value,
      page: pageNum.value
    })
    store.value = { ...store.value, ...result }
  } finally {
    clearReadLoading.value = false
  }
}

const patchOne = ({ linkId, link, labels }: LinkUpdated) => {
  console.log('Incoming labels', labels, 'Stored labels', store.value.labels)
  store.value.labels = labels

  if (!link) {
    delete store.value.links[linkId]
    return
  }
  // We do NOT check for equality on these, because the pagination SQL doesn't
  // check for equality. We need those to match.
  const isOnPreviousPage =
    store.value.prevPageToken === null ? false : link.sort_key < store.value.prevPageToken
  const isOnNextPage =
    store.value.nextPageToken === null ? false : link.sort_key > store.value.nextPageToken
  if (isOnPreviousPage || isOnNextPage) {
    delete store.value.links[linkId]
  } else {
    store.value.links[linkId] = link
  }
}

//import { getCurrentInstance } from 'vue'
//console.log('hb', getCurrentInstance().proxy.$honeybadger)

// This handles on-mount behavior, too.
//
// TODO: I'm sure there's an idiomatic way to handle loading data in response to
// page load, ref changes...
watch([pageNum, labelsFilter], refresh, { immediate: true })
</script>

<template>
  <div :class="`grid gap-x-3 ${styles['app-container']}`">
    <Sidebar :loading="isLoading" v-slot="{ setDrawerOpen }">
      <li>
        <span class="flex items-center gap-x-1">
          <IconTag class="inline" size="20" />
          Labels
        </span>
        <ul v-if="!isLoading" class="flex flex-col gap-1 px-5" data-testid="labels">
          <li v-for="label in labelsSorted" :key="label.label" :data-label="label.label">
            <RouterLink
              :to="{ ...route, query: { ...route.query, labels_all: label.label } }"
              @click="setDrawerOpen(false)"
              class="block"
            >
              {{ label.label }} ({{ label.count }})
            </RouterLink>
          </li>
        </ul>
        <LabelsSkeleton v-else />
      </li>
    </Sidebar>

    <div>
      <div class="flex gap-x-4 items-end">
        <Button @click="refresh" label="Refresh" severity="contrast" outlined>
          <template #icon><IconReload size="20" /></template>
        </Button>

        <Button
          @click="clearRead"
          severity="secondary"
          outlined
          :loading="clearReadLoading"
          label="Clear Read"
        >
          <template #icon><IconMistOff size="20" /></template>
        </Button>

        <AddLink @shouldRefresh="refresh" />
      </div>

      <header class="font-semibold text-xl mb-4 mt-4">
        <template v-if="labelsFilter.all?.length">
          Filter: {{ labelsFilter.all.join(', ') }}
        </template>
        <template v-else-if="labelsFilter.trashed">Trash</template>
        <template v-else-if="labelsFilter.domain">Site: {{ labelsFilter.domain }}</template>
        <template v-else>Inbox</template>
      </header>

      <template v-if="!isLoading">
        <template v-if="store.prevPageToken">
          <div class="flex justify-between">
            <Button
              class="px-4 py-2"
              as="router-link"
              :to="adjacentPageUrl('previous')"
              :class="{ invisible: !store.prevPageToken }"
              label="Previous page"
            />
            <Button
              class="px-4 py-2"
              as="router-link"
              :to="adjacentPageUrl('next')"
              :class="{ invisible: !store.nextPageToken }"
              label="Next Page"
            />
          </div>
        </template>

        <ul class="flex flex-col gap-y-4">
          <li v-for="link in sortedLinks" :key="link.link_id" class="content">
            <LinkListItem
              :link="link"
              :filter="labelsFilter"
              :labels="store.labels"
              :refresh="refresh"
              :onUpdated="patchOne"
              :page="pageNum"
            />
          </li>
        </ul>

        <div class="flex justify-between">
          <Button
            class="px-4 py-2"
            as="router-link"
            :to="adjacentPageUrl('previous')"
            :class="{ invisible: !store.prevPageToken }"
            label="Previous page"
          />
          <Button
            class="px-4 py-2"
            as="router-link"
            :to="adjacentPageUrl('next')"
            :class="{ invisible: !store.nextPageToken }"
            label="Next Page"
          />
        </div>
      </template>
      <template v-else>
        <div class="flex flex-col gap-y-4">
          <ListItemSkeleton v-for="i in new Array(3)" />
        </div>
      </template>
    </div>
  </div>
</template>
