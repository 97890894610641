import type { LinkDetail, UserLinks } from 'server'

export const sortLinks = (
  links: Record<string, LinkDetail>,
  trashSorting = false
): LinkDetail[] => {
  return Object.values(links).toSorted((a, b) => (a.sort_key < b.sort_key ? -1 : 1))
}

export const sortLabels = (labels: UserLinks['labels']): { label: string; count: number }[] =>
  Object.entries(labels)
    .map(([label, count]) => ({ label, count }))
    .toSorted(({ label: a }, { label: b }) => {
      // We need Emoji_Modifier and Emoji_Component because emoji are complex
      // and otherwise you get borked output like only removing some of the code
      // points from *️⃣, leaving a weird byte sequence that we sort on.
      //
      // We can't use \p{Emoji_Presentation} here because it detects characters
      // that are ONLY valid as an emoji (i.e., it excludes ASCII/Unicode
      // icons). That makes everything sort all goofy. E.g., '🖥️
      // requires_computer' gets sorted to the bottom of our sample data, even
      // after alphabetically-later works
      a = a.replace(/(\p{Emoji}|\p{Emoji_Modifier}|\p{Emoji_Component})+/gu, '').trim()
      b = b.replace(/(\p{Emoji}|\p{Emoji_Modifier}|\p{Emoji_Component})+/gu, '').trim()
      return a.toLowerCase() < b.toLowerCase() ? -1 : 1
    })
