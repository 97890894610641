<script setup lang="ts">
import { ref, watchEffect } from 'vue'

import Button from 'primevue/button'
import Checkbox from 'primevue/checkbox'
import InputText from 'primevue/inputtext'

import { IconPlus } from '@tabler/icons-vue'

import { sortLabels } from '../lib/util'

import type { LinkDetail, UserLinks } from 'server'

const props = defineProps<{ link: LinkDetail; labels: UserLinks['labels'] }>()
const emit = defineEmits<{ update: [string[]] }>()

const enabledLabels = ref(props.link.labels_display.slice())

const novelLabels = ref<{ label: string; enabled: boolean }[]>([])
watchEffect(() => {
  const mergedEnabledLabels: string[] = [
    ...Array.from(enabledLabels.value),
    ...novelLabels.value
      .filter(({ label, enabled }) => label.length > 0 && enabled)
      .map(({ label }) => label)
  ]
  emit('update', mergedEnabledLabels)
})
const onNewLabelKeypress = (event: Event & { target: HTMLInputElement }, i: number) => {
  novelLabels.value[i].enabled = Boolean(event.target.value)
}
</script>

<template>
  <!-- We tried using CSS grid here, but it can't distribute content by row??? I
  think??? Anyway we want the fixed column count regardless: spreading the
  labels out across 3/4/5 columns just looks bad and is hard to scan.  -->
  <div class="columns-1 sm:columns-2">
    <label
      v-for="label in sortLabels(props.labels).map(({ label }) => label)"
      :key="label"
      class="flex items-center gap-x-2 whitespace-nowrap"
    >
      <Checkbox v-model="enabledLabels" :key="label" :value="label" name="label" />
      {{ label }}
    </label>

    <div v-for="(novelLabel, i) of novelLabels" :key="i" class="flex gap-x-2 items-center">
      <Checkbox v-model="novelLabel.enabled" name="label" :binary="true" />
      <InputText
        class="min-w-0"
        v-model="novelLabel.label"
        @input="onNewLabelKeypress($event as any, i)"
        size="small"
        data-testid="label-blank"
      />
    </div>

    <Button
      label="Add new label..."
      @click="novelLabels.push({ label: '', enabled: false })"
      size="small"
      severity="secondary"
      class="w-max -ml-3"
      data-testid="labels-add-new"
    >
      <template #icon><IconPlus size="20" /></template>
    </Button>
  </div>
</template>
