<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'

import {
  IconInbox,
  IconTrash,
  IconMenu2,
  IconArrowsSplit,
  IconSettings,
  IconLogout,
  IconHelp,
  IconPaywall
} from '@tabler/icons-vue'

import Button from 'primevue/button'

import SidebarEl from './SidebarEl.vue'

import trpc from '../../lib/trpc'

defineProps<{
  loading: boolean
}>()

const router = useRouter()

const drawerOpen = ref(false)

const subscriptionState = ref<string | null | undefined>(undefined)

onMounted(async () => {
  subscriptionState.value = await trpc.subscriptions.getState.query()
})

const logout = async () => {
  await trpc.logout.mutate()
  router.push({ name: 'login' })
}

const onclick = (e: MouseEvent) => {
  if ((e.target as HTMLElement).closest('a[href^="/"]')) {
    drawerOpen.value = false
  }
}
</script>

<template>
  <div>
    <SidebarEl v-model="drawerOpen">
      <nav data-testid="sidebar">
        <ul class="flex flex-col gap-2 p-2" @click="onclick">
          <li>
            <RouterLink :to="{ name: 'home' }" class="flex items-center gap-x-1">
              <IconInbox class="inline" size="20" />
              Inbox
            </RouterLink>
          </li>

          <li>
            <RouterLink
              :to="{ name: 'triage' }"
              class="flex items-center gap-x-1"
              data-testid="triage"
            >
              <IconArrowsSplit class="inline" size="20" />
              Triage
            </RouterLink>
          </li>

          <li>
            <RouterLink
              :to="{ name: 'home', query: { trashed: 'true' } }"
              class="flex items-center gap-x-1"
            >
              <IconTrash class="inline" size="20" />
              Trash
            </RouterLink>
          </li>

          <slot :setDrawerOpen="(value: boolean) => (drawerOpen = value)" />

          <li v-if="subscriptionState !== undefined && subscriptionState !== 'active'">
            <a href="/api/subscribe/redirectToCheckout">
              <span
                class="flex items-center gap-x-1 pr-2 py-1 w-max border-2 border-blue-400 rounded-lg"
              >
                <IconPaywall class="inline" size="20" />
                Subscribe!
              </span>
            </a>
          </li>

          <li>
            <RouterLink :to="{ name: 'settings' }" class="flex items-center gap-x-1">
              <IconSettings class="inline" size="20" />
              Settings
            </RouterLink>
          </li>

          <li>
            <RouterLink :to="{ name: 'inbox-help' }" class="flex items-center gap-x-1">
              <IconHelp class="inline" size="20" />
              Help
            </RouterLink>
          </li>

          <li>
            <Button text severity="secondary" @click="logout" label="Log out" class="p-0">
              <template v-slot:icon><IconLogout size="20" /></template>
            </Button>
          </li>
        </ul>
      </nav>
    </SidebarEl>

    <Teleport to="#sidebar-hamburger">
      <Button
        severity="contrast"
        variant="text"
        class="sm:hidden text-black"
        @click="drawerOpen = true"
      >
        <template #icon><IconMenu2 /></template>
      </Button>
    </Teleport>
  </div>
</template>
